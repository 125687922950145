import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { LiveChatWidget } from '@livechat/widget-react'

import logo from '../../../assets/img/landingPage/AVAP_Framework.svg'
import icn_avap from '../../../assets/img/logos/icon_avap.svg'
import icn_extension from '../../../assets/img/logos/extensionsIcon.svg'
import icn_ds from '../../../assets/img/logos/icono_DS.svg'
import icn_avs from '../../../assets/img/logos/icono_VS.svg'
import icn_lang from '../../../assets/img/logos/icon_avap.svg'
import icn_gate from '../../../assets/img/logos/icono_Gateway.svg'
import icn_vc from '../../../assets/img/logos/AVAP_APIControVersion.svg'
import icn_publisher from '../../../assets/img/logos/icono_publisher.svg'
import icn_market from '../../../assets/img/logos/AVAP_Marketplace_icon.svg'
import icn_connector from '../../../assets/img/logos/AVAP_Connector_Icono.svg'
import icn_collab from '../../../assets/img/logos/icono_CollaborativeN.svg'
import icn_cloud from '../../../assets/img/logos/icono_Cloud.svg'
import icn_platon from '../../../assets/img/logos/icono_Platon.svg'
import icn_communities from '../../../assets/img/logos/icono_Communities.svg'
import icn_framework from '../../../assets/img/logos/icono_Framework.svg'
import icn_alexandria from '../../../assets/img/logos/icono_Alexandria.svg'
import icn_blog from '../../../assets/img/logos/icono_blog.svg'
import icn_101 from '../../../assets/img/logos/101-menu.svg'

import transparent from '../../../assets/img/logos/transparent.png'



//ui
import chevron from '../../../assets/img/ui_icons/chevron.svg'
import user from '../../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../../assets/img/ui_icons/fa-doc.svg'



const productsLinks = [
    {
        title: "Build Virtual APIs",
        items: [
            {
                text: "AVAP (Advanced Virtual API Programming)",
                href: "/avap",
                desc: "High-level virtual programming language designed and created for API development.",
                img: icn_avap // Variable que representa el ícono
            },
            {
                text: "AVAP Dev Studio",
                href: "/devstudio",
                desc: "AVAP’s IDE for complete project management, API development, code maintenance, and API publication.              ",
                img: icn_ds // Variable que representa el ícono
            },
            {
                text: "AVAP Dev Studio Extensions for Microsoft Visual Code",
                href: "/vsc-extensions",
                desc: "AVAP language extensions to build, version, and publish APIs seamlessly in Microsoft Visual Code.",
                img: icn_ds // Variable que representa el ícono
            }

        ],
    },
    {
        title: "Run APIs",
        items: [
            {
                text: "AVAP Gateway Advanced",
                href: "/gateway",
                desc: "Intelligent, secure gateway for dynamic traffic routing, optimized performance, and scalable security.",
                img: icn_gate// Variable que representa el ícono
            },
            {
                text: "AVAP Virtual Server",
                href: "/avs",
                desc: "Lightweight universal API server enabling lifecycle management and intelligent traffic optimization.",
                img: icn_avs // Variable que representa el ícono
            },
            {
                text: "AVAP Interpreter & Language Server",
                href: "/interpreter",
                desc: "Fast, scalable API development with real-time interpretation and AI-powered code enhancement.",
                img: icn_lang // Variable que representa el ícono
            }

        ],
    },
    {
        title: "Test & Deliver APIs",
        items: [
            {
                text: "AVAP API Version Control",
                href: "/version-control",
                desc: "Designed for API versioning with tracking, multi-version management, and backward compatibility.",
                img: icn_vc// Variable que representa el ícono
            },
            {
                text: "AVAP API Publisher",
                href: "/api-publisher",
                desc: "Streamlined API publishing with robust management, version control, and seamless platform integration.",
                img: icn_publisher // Variable que representa el ícono
            },
        ],
    },
    {
        title: "Power Up APIs",
        items: [
            {
                text: "AVAP Marketplace",
                href: "/avap-marketplace",
                desc: "A collaborative platform for discovering, integrating, and monetizing API products.",
                img: icn_market // Variable que representa el ícono
            },
            {
                text: "AVAP Connectors",
                href: "/avap-connectors",
                desc: "Pre-configured components designed to simplify access to external systems, platforms, and databases.",
                img: icn_connector // Variable que representa el ícono
            },
        ],
    },
];



const servicesLinks = [
    {
        title: "PROFESSIONAL",
        items: [
            {
                text: "AVAP Collaborative Network",
                href: "/collaborative",
                desc: "Peer-to-peer platform for scalable API development, deployment, and optimized processing across servers.",
                img: icn_collab // Variable que representa el ícono
            },
            {
                text: "AVAP Cloud Professional",
                href: "/cloud",
                desc: "A powerful, flexible platform for API lifecycle management. Infrastructure and technology to start developing.",
                img: icn_cloud // Variable que representa el ícono
            }
        ],
    },
    {
        title: "BUSSINES",
        items: [
            {
                text: "AVAP Cloud Business",
                href: "/cloud",
                desc: "A powerful, flexible platform for API lifecycle management. Infrastructure to take you to the next level.",
                img: icn_cloud // Variable que representa el ícono
            }
        ],
    },
    {
        title: "CORPORATE",
        items: [
            {
                text: "AVAP Cloud Enterprise",
                href: "/cloud",
                desc: "A powerful, flexible platform for API lifecycle management. Full deployment control of infrastructure, tailored for corporations.",
                img: icn_cloud// Variable que representa el ícono
            }
        ],
    }
];


const resourcesLinks = [
    {
        title: "DEVELOPER SUPPORT",
        items: [
            {
                text: "AVAP Developer Portal",
                href: "/default",
                desc: "Centralized hub for AVAP technical documentation, code samples, and more for AVAP Framework.",
                img: icn_ds // Variable que representa el ícono
            },
            {
                text: "AVAP Communities",
                href: "https://speed-computing-1656.my.site.com/communities/s/",
                desc: "Collaborative forum for developers to share knowledge, solve challenges, and innovate.",
                img: icn_communities // Variable que representa el ícono
            },
            {
                text: "AVAP Collaborative Network",
                href: "/collaborative",
                desc: "Peer-to-peer platform for scalable API development, deployment, and optimized processing across servers.",
                img: icn_collab // Variable que representa el ícono
            },
        ],
    },
    {
        title: "KNOWLEDGE CENTER",
        items: [
            {
                text: "AVAP Alexandria",
                href: "https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US",
                desc: "Centralized hub for product guides, legal documents, and resources.",
                img: icn_alexandria // Variable que representa el ícono
            },
            {
                text: "AVAP Blog",
                href: "http://blog.avapframework.com/",
                desc: "Find our insights on technology, AVAP, APIs, innovation, business, and product updates.",
                img: icn_blog // Variable que representa el ícono
            }
        ],
    },
    {
        title: "API PRODUCTS",
        items: [
            {
                text: "AVAP Marketplace",
                href: "/avap-marketplace",
                desc: "A collaborative platform for discovering, integrating, and monetizing API products.",
                img: icn_market// Variable que representa el ícono
            }
        ],
    },
    {
        title: "NEWEST FEATURES",
        items: [
            {
                text: "Product Roadmap",
                href: "/roadmap",
                desc: "Visualize and explore the next steps of our complete AVAP ecosystem effortlessly.",
                img: icn_framework// Variable que representa el ícono
            }
        ],
    }
];


const companyLinks = [
    {
        title: "COMPANY",
        items: [
            {
                text: "About Us",
                href: "/about",
                desc: "Discover AVAP’s mission, values, and journey in API innovation.",
                img: icn_101 // Variable que representa el ícono
            },
            {
                text: "Contact us",
                href: "#contactUs",
                desc: "Reach out for support, inquiries, or collaboration opportunities.",
                img: transparent
            }
        ],
    }
];


const sideLinks = [
    {
        title: "Open Source ",
        items: [
            {
                text: "PLATON (Programming Language Architecture for True Open virtualizatioN)",
                href: "/platon",
                desc: "Open-source project offering the kernel and specifications of a virtual programming language as an accessible resource.",
                img: icn_platon,
            },]
    },
    {
        title: "GETTING STARTED",
        items: [
            { text: "Create your AVAP ID", href: "https://staging.avapframework.com/Register", desc: '', img: user },
            { text: "Download basic software", href: "#", desc: '', img: download },
            { text: "Create your Developer Token", href: "#", desc: '', img: key },
            { text: "Create your first API Project", href: "#", desc: '', img: doc }

        ]
    },

];



const sideLinks2 = [
    {
        title: "LEGAL ",
        items: [
            {
                text: "Terms & Conditions AVAP Collaborative Networks",
                href: "#virtual",
                desc: "Understand the rules governing your use of AVAP Collaborative Network."
            },
            {
                text: "Terms & Conditions AVAP Cloud",
                href: "#virtual",
                desc: "Understand the rules governing your use of AVAP Cloud."
            },
            {
                text: "Privacy Policy",
                href: "#virtual",
                desc: "Learn how AVAP collects, uses, and protects your data."
            }
        ]
    }

];



const Navbar = () => {
    const history = useHistory();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuOpen2, setIsMenuOpen2] = useState(false);
    const [isMenuOpen3, setIsMenuOpen3] = useState(false);
    const [isMenuOpen4, setIsMenuOpen4] = useState(false);
    const [isMenuOpen5, setIsMenuOpen5] = useState(false);

    const handleCloseAll = () => {
        setIsMenuOpen(false)
        setIsMenuOpen2(false)
        setIsMenuOpen3(false)
        setIsMenuOpen4(false)
        setIsMenuOpen5(false)
    }

    const [isMobileProductsOpen, setIsMobileProductsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleMenu = (menu) => {
        setActiveMenu(activeMenu === menu ? null : menu);
    };

    return (
        <>
        <LiveChatWidget license="18865161" group="0" />
            <nav className="lf-navbar">
                <div className="lf-navbar-brand">
                    <a onClick={() => history.push('/framework')} style={{ backgroundImage: `url(${logo})` }} className="lf-brand-logo" ></a>
                    <button
                        className="lf-menu-toggle"
                        aria-label="Toggle menu"
                        onClick={toggleDrawer}
                    >
                        ☰
                    </button>
                </div>



                {isMenuOpen && (
                            <div className="lf-floating-menu">
                                <div className="fl-left">
                                    {productsLinks.map((section, index) => (
                                        <div className="lf-menu-section" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        <a onClick={() => history.push(item.href)}>
                                                            <img src={item.img} alt={item.text} className="fl-icon" />
                                                            <div className="wrapper">
                                                                <span className="title"> {item.text}</span>
                                                                <span className="desc">{item.desc}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                <div className="fl-right">
                                    {/* Mapeo de la sección "open Source" */}
                                    <div className="openSource">
                                        {sideLinks
                                            .filter((section) => section.title.trim().toLowerCase() === "open source")
                                            .map((section, index) => (
                                                <div className="lf-menu-section" key={index}>
                                                    <h4>{section.title}</h4>
                                                    <ul>
                                                        {section.items.map((item, idx) => (
                                                            <li key={idx}>
                                                                <a onClick={() => history.push(item.href)} className="d-flex">
                                                                    <img className="" src={item.img} alt="" />
                                                                    <div>
                                                                        <span className="title">{item.text}</span>
                                                                        {item.desc && <span className="desc">{item.desc}</span>}
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="dividerMenu"></div>
                                    {/* Mapeo de la sección "GETTING STARTED" */}
                                    <div className="started">
                                        {sideLinks
                                            .filter((section) => section.title.trim().toLowerCase() === "getting started")
                                            .map((section, index) => (
                                                <div className="lf-menu-section" key={index}>
                                                    <h4>{section.title}</h4>
                                                    <ul>
                                                        {section.items.map((item, idx) => (
                                                            <li key={idx}>
                                                                <a onClick={() => history.push(item.href)}>
                                                                    <img className="icon-small" src={item.img} alt="" />
                                                                    <span className="title">{item.text}</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                    </div>

                                    <div className="devPortal">

                                    </div>
                                </div>

                            </div>
                        )}



{(isMenuOpen3 && false) && (
                            <div className="lf-floating-menu">
                                <div className="fl-left">
                                    {resourcesLinks.map((section, index) => (
                                        <div className="lf-menu-section" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        <a onClick={() => history.push(item.href)}>
                                                            <img src={item.img} alt={item.text} className="fl-icon" />
                                                            <div className="wrapper">
                                                                <span className="title"> {item.text}</span>
                                                                <span className="desc">{item.desc}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        )}


{isMenuOpen4 && (
                            <div className="lf-floating-menu">
                                <div className="fl-left">
                                    {resourcesLinks.map((section, index) => (
                                        <div className="lf-menu-section-3" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        <a onClick={() => history.push(item.href)}>
                                                            <img src={item.img} alt={item.text} className="fl-icon" />
                                                            <div className="wrapper">
                                                                <span className="title"> {item.text}</span>
                                                                <span className="desc">{item.desc}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>


                            </div>
                        )}


{isMenuOpen5 && (
                            <div className="lf-floating-menu">
                                <div className="fl-left" style={{ width: '30%' }}>
                                    {companyLinks.map((section, index) => (
                                        <div className="lf-menu-section" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        <a onClick={() => history.push(item.href)}>
                                                            <img src={item.img} alt={item.text} className="fl-icon" />
                                                            <div className="wrapper">
                                                                <span className="title"> {item.text}</span>
                                                                <span className="desc">{item.desc}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                <div className="fl-right" style={{ width: '70%' }}>
                                    {/* Mapeo de la sección "open Source" */}
                                    <div className="openSource">
                                        {sideLinks2
                                            .map((section, index) => (
                                                <div className="lf-menu-section" key={index}>
                                                    <h4>{section.title}</h4>
                                                    <ul>
                                                        {section.items.map((item, idx) => (
                                                            <li key={idx}>
                                                                <a onClick={() => history.push(item.href)} className="d-flex flex-column ml-4 mb-5">
                                                                    <span className="title">{item.text}</span>
                                                                    {item.desc && <span className="desc">{item.desc}</span>}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                    </div>

                                    {/* Mapeo de la sección "GETTING STARTED" */}


                                    {/*}
                                    <div className="started">
                                        {sideLinks2
                                            .filter((section) => section.title.trim().toLowerCase() === "getting started")
                                            .map((section, index) => (
                                                <div className="lf-menu-section" key={index}>
                                                    <h4>{section.title}</h4>
                                                    <ul>
                                                        {section.items.map((item, idx) => (
                                                            <li key={idx}>
                                                                <a onClick={() => history.push(item.href)}>
                                                                    <span className="title">{item.text}</span>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                    </div>

                                    <div className="devPortal">

                                    </div>
                                    */}
                                </div>

                            </div>
                        )}

                <ul className="lf-navbar-main-links">
                    <li
                        className="lf-menu-item"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            handleCloseAll();
                            setIsMenuOpen(!isMenuOpen)
                        }}
                    //onMouseEnter={() => setIsMenuOpen(true)}
                    //onMouseLeave={() => setIsMenuOpen(false)}
                    >
                        <span className="lf-menu-link">Products</span>
                        
                    </li>
                    <li><span
                        onClick={() => history.push('/cloud')}
                        style={{ cursor: 'pointer' }}
                        className="lf-menu-link">AVAP Cloud</span>

                        {/* {isMenuOpen2 && (
                            <div className="lf-floating-menu">
                                <div className="fl-left">
                                    {servicesLinks.map((section, index) => (
                                        <div className="lf-menu-section-3" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        <a onClick={() => history.push(item.href)}>
                                                            <img src={item.img} alt={item.text} className="fl-icon" />
                                                            <div className="wrapper">
                                                                <span className="title"> {item.text}</span>
                                                                <span className="desc">{item.desc}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                {

                                }
                            </div>
                        )} */}

                    </li>
                    <li>
                        <a
                            onClick={() => history.push('/brunix')}
                            style={{ cursor: 'pointer' }}
                            className="lf-menu-link">AI Brunix</a>


                        



                    </li>
                    <li><span
                        onClick={() => {
                            handleCloseAll();
                            setIsMenuOpen4(!isMenuOpen4)
                        }}
                        style={{ cursor: 'pointer' }}
                        className="lf-menu-link">Resources</span>


                        



                    </li>
                    <li><span
                        onClick={() => {
                            handleCloseAll();
                            setIsMenuOpen5(!isMenuOpen5)
                        }
                        }
                        style={{ cursor: 'pointer' }}
                        className="lf-menu-link">Company</span>




                       




                    </li>
                </ul>

                <ul className="lf-navbar-actions">
                    <li><a onClick={() => history.push("/avap_cloud")} className="lf-menu-item lf-button">Get a Demo</a></li>
                    <li><a onClick={() => history.push("/avap_cloud")} className="lf-menu-item lf-button lf-primary">Start for Free</a></li>
                </ul>
            </nav>

            {/* Drawer lateral para móvil */}
            <div className={`lf-drawer ${isDrawerOpen ? "lf-drawer-open" : ""}`}>
                <button className="lf-close-drawer" onClick={toggleDrawer}>
                    ✖
                </button>
                <ul className="lf-drawer-links">
                    <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMobileProductsOpen(!isMobileProductsOpen)}
                        >
                            Products{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMobileProductsOpen ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMobileProductsOpen && (
                            <ul className="lf-drawer-submenu">
                                {productsLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                <a onClick={() => history.push(item.href)}>{item.text}</a>
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li>

                    {/* Services */}
                    {/* <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMenuOpen2(!isMenuOpen2)}
                        >
                            Services{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMenuOpen2 ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMenuOpen2 && (
                            <ul className="lf-drawer-submenu">
                                {servicesLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                <a onClick={() => history.push(item.href)}>{item.text}</a>
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li> */}
                    <li><a onClick={() => history.push('/cloud')} className="lf-drawer-item lf-drawer-dropdown">AVAP Cloud  </a></li>
                    <li><a onClick={() => history.push('/brunix')} className="lf-drawer-item lf-drawer-dropdown">AI Brunix</a></li>
                    {/* Resources */}
                    <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMenuOpen4(!isMenuOpen4)}
                        >
                            Resources{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMenuOpen4 ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMenuOpen4 && (
                            <ul className="lf-drawer-submenu">
                                {resourcesLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                <a onClick={() => history.push(item.href)}>{item.text}</a>
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li>

                    {/* Company */}
                    <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMenuOpen5(!isMenuOpen5)}
                        >
                            Company{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMenuOpen5 ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMenuOpen5 && (
                            <ul className="lf-drawer-submenu">
                                {companyLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                <a onClick={() => history.push(item.href)}>{item.text}</a>
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li>
                    <li><a href="#signin" className="lf-drawer-item lf-button mb-2 mt-5">Sign In</a></li>
                    <li><a href="#join" className="lf-drawer-item lf-button lf-primary">Join Today</a></li>
                </ul>
            </div>

            {isDrawerOpen && <div className="lf-backdrop" onClick={toggleDrawer}></div>}

            {(isMenuOpen || isMenuOpen2 || isMenuOpen4 || isMenuOpen5) && <div onClick={() => { handleCloseAll() }} className="lf-menu-overlay"></div>}
        </>
    );
};

export default Navbar;
